<template>
  <div class="gec-user-lists">
    <card-view v-if="showCardView"
               :columns="columns"
               :rows="users"
               :can-show-password="canShowPassword"
               :users="users"
               :update-show-password="updateShowPassword"
               :can-have-child-users="canHaveChildUsers"
               :is-processing="isProcessing"
               :can-create-sub-users="canCreateSubUsers"
               :can-view-student-report="canViewStudentReport"
               :is-a-teacher="isATeacher"
               :is-a-student="isAStudent"
               :self="self"
               :is-a-super="isASuper"
               :is-a-parent="isAParent"
               :can-attach-parent-to-student="canAttachParentToStudent"
               :is-trashed-users="isTrashedUsers"
               :get-random-variant="getRandomVariant"
               :get-initials="getInitials"
               :show-all-password="showAllPassword"
               @onViewChildUsers="index => $emit('onViewChildUsers', index)"
               @onAttachParent="index => $emit('onAttachParent', index)"
               @onEditUser="index => $emit('onEditUser', index)"
               @onDeleteUser="index => $emit('onDeleteUser', index)"
               @onRestoreUser="index => $emit('onRestoreUser', index)"
               @onCreateUser="index => $emit('onCreateUser', index)"
               @onResetPassword="index => $emit('onResetPassword', index)"
               @onViewUserRelations="index => $emit('onViewUserRelations', index)"
               @onViewSubUsers="index => $emit('onViewSubUsers', index)"
               @onDeactivateUser="(index) => $emit('onDeactivateUser', index)"
               @onViewStudentClassrooms="data => $emit('onViewStudentClassrooms', data)"
    />
    <vue-good-table
      v-else
      :columns="columns"
      :rows="users"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'fullname'"
          class="text-nowrap"
        >
          <router-link
            :to="{ name: 'user-profile',params:{name:`${props.row.fullname.replaceAll('_','-').replaceAll(' ','-')}_${props.row.id}`}}"
            target="_blank"
            @click.stop
          >
            <span
              class=""
            >
              <b-avatar
                v-if="props.row.avatar"
                badge
                :badge-variant="props.row.is_online ? 'success' : 'secondary'"
                badge-offset="-0.2em"
                :src="props.row.avatar"
                class="ml-1 mt-1"
                square
                size="42"
              />

              <b-avatar
                v-else
                class="ml-1 mt-1"
                badge
                :badge-variant="props.row.is_online ? 'success' : 'secondary'"
                badge-offset="-0.2em"
                :text="getInitials(props.row.fullname)"
                :size="42"
                :variant="getRandomVariant()"
                square
              />
              <span class="text-nowrap m-0 ml-1 p-0">{{ props.row.fullname }}</span>
              <h5
                v-if="props.row.nickname"
                class="ml-3 pl-2 nickname"
              >({{ props.row.nickname }})</h5>

            </span>
          </router-link>
          <span v-if="AUTH_USER().user_level <= 7 && props.row.usertype !== 'student'"
                class="d-block"
                style="margin-top:10px"
          >
            <b-badge
              class="rounded-pill success ml-2"
            >{{ props.row.child_count }}</b-badge>
            <i class="dark"
               role="button"
               @click="$emit('onViewChildUsers', props.index)"
            > {{ $t('user-module.child-users') }}</i>
          </span>
        </span>
        <span v-else-if="props.column.field==='country'">
          <span class="d-block">{{ props.row.country }}</span>
          <span v-b-tooltip.hover="$t('user-module.user-table.language')">({{ props.row.language }})</span>
        </span>
        <span
          v-else-if="props.column.field === 'usertype'"
          class="text-nowrap"
        >
          <span>{{ $t("user-types." + props.row.usertype) }}</span>
          <span class="d-flex align-items-center">
            <template v-if="props.row.password">
              <span
                v-if="canShowPassword(props.row.id) || showAllPassword"
                class="align-items-center"
              >
                <span class="mr-1"
                      @click="updateShowPassword(props.row.id)"
                >{{ props.row.password }}</span>
              </span>
              <span
                v-else
                class="align-items-center"
              >
                <span
                  class="mr-1"
                  style="margin-top: 8px;"
                  @click="updateShowPassword(props.row.id)"
                >**********</span>
              </span>
              <b-button v-b-tooltip.hover="$t('user-module.copy-password')"
                        variant="flat-success"
                        class="btn-icon copy-button"
                        :disabled="isProcessing"
                        @click="copyTextToClipboard(props.row.password)"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </template>
            <template v-else>
              <span class="align-items-center h6 text-danger">
                {{ $t('actions.reset-password') }}
              </span>
            </template>

          </span>
          <span
            v-if="props.row.access_type === 'full_deactivate'"
            class="text-nowrap align-items-center alert alert-danger"
            style="font-size:12px"
          >{{ $t('deactivate-user.fully-deactivated') }}</span>
          <span
            v-if="props.row.access_type === 'partial_deactivate'"
            class="text-nowrap align-items-center alert alert-warning"
            style="font-size:12px"
          >{{ $t('deactivate-user.partially-deactivated') }}</span>
        </span>
        <span v-else-if="props.column.field === 'action'"
              class="d-flex align-items-center flex-wrap"
              style="gap:7px;"
        >
          <span v-if="canHaveChildUsers(users[props.index].usertype)">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('actions.show-childusers')"
              :disabled="isProcessing"
              variant="outline-success"
              class="btn-icon"
              @click="$emit('onViewChildUsers', props.index)"
            >
              <feather-icon icon="UsersIcon" />
            </b-button>
          </span>
          <span v-if="users[props.index].usertype === 'national'">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('actions.show-curriculum-user')"
              :disabled="isProcessing"
              variant="outline-success"
              class="btn-icon"
              @click="$emit('onViewChildUsers', props.index, 'curriculum')"
            >
              <feather-icon icon="BookIcon" />
            </b-button>
          </span>
          <!-- <span v-if="canCreateSubUsers(users[props.index].usertype)">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('actions.show-subusers')"
              :disabled="isProcessing"
              variant="outline-primary"
              class="btn-icon mr-1 sm-mt-2"
              @click="$emit('onViewSubUsers', props.index)"
            >
              <feather-icon icon="UsersIcon" />
            </b-button>
          </span> -->
          <span v-if="canViewStudentReport(self.usertype) && (isATeacher(users[props.index].usertype) || isAStudent(users[props.index].usertype))"
                class="d-flex"
                style="gap:7px;"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('users-module.show-dashboard')"
              :disabled="isProcessing"
              variant="outline-success"
              class="btn-icon"
              @click="$router.push({ name: `student-dashboard-for-${self.usertype}`,params: {id: props.row.id }, query: { from: 'users' } })"
            >
              <feather-icon icon="HomeIcon" />
            </b-button>
            <b-button
              v-if="isASuper || isATeacher"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('users-module.view-classrooms')"
              :disabled="isProcessing"
              variant="outline-info"
              class="btn-icon"
              @click="$emit('onViewStudentClassrooms', {id:props.row.id,usertype:users[props.index].usertype})"
            >
              <feather-icon icon="AirplayIcon" />
            </b-button>
          </span>
          <span v-if="isAStudent(users[props.index].usertype)">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-delete-user
              v-b-tooltip.hover="'View Attendance'"
              v-b-tooltip.hover.top="$t('users-module.view-attendance')"
              :disabled="isProcessing"
              variant="outline-info"
              class="btn-icon "
              @click="$router.push({ name: `${self.usertype}-view-attendance`, query: { fullname: props.row.fullname }, params: {id: props.row.id } })"
            >
              <feather-icon icon="ClockIcon" />
            </b-button>
          </span>
          <!-- TODO:: check if school user-->
          <span v-if="users[props.index].usertype === 'school'">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-delete-user
              v-b-tooltip.hover="'View Attendance'"
              v-b-tooltip.hover.top="$t('user-module.add-teacher')"
              :disabled="isProcessing"
              variant="primary"
              class="btn-icon"
              @click="$emit('onViewUserRelations', users[props.index].id, 'teacher', 'child')"
            >
              <span class="material-icons"
                    style="font-size: 1rem;"
              >co_present</span>
            </b-button>
          </span>
          <span v-if="users[props.index].usertype === 'school' || users[props.index].usertype === 'teacher'">
            <b-button
              v-b-modal.modal-delete-user
              v-b-tooltip.hover="'View Attendance'"
              v-b-tooltip.hover.top="$t('user-module.add-student')"
              :disabled="isProcessing"
              variant="success"
              class="btn-icon"
              @click="$emit('onViewUserRelations', users[props.index].id, 'student', 'child')"
            >
              <span class="material-icons"
                    style="font-size: 1rem;"
              >group_add</span>
            </b-button>
          </span>
          <span v-if="!isAParent(self.usertype)">
            <b-dropdown
              ref="testDropDown"
              variant="link"
              toggle-class="text-decoration-none pl-0 inline"
              no-caret
              tag="button"
              class="p-0 m-0 inline-block pl-0"
            >
              <template #button-content>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :disabled="isProcessing"
                  variant="outline-secondary"
                  class="btn-icon"
                >
                  <feather-icon icon="MoreVerticalIcon" />
                </b-button>
              </template>
              <b-dropdown-item
                v-if="
                  canAttachParentToStudent(self.usertype) &&
                    isAStudent(users[props.index].usertype)
                "
                v-b-tooltip.hover.top="$t('users-module.assign-parent')"
                @click="$emit('onAttachParent', props.index)"
              >

                <feather-icon icon="PaperclipIcon" />
                <span class="pl-1">{{ $t('users-module.assign-parent') }}</span>

              </b-dropdown-item>
              <b-dropdown-item @click="$emit('onEditUser', props.row.originalIndex)">
                <feather-icon icon="EditIcon" />
                <span class="pl-1">{{ $t('edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="$emit('onDeleteUser', props.index)">
                <feather-icon icon="TrashIcon" />
                <span class="pl-1">{{ $t('delete') }}</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="isTrashedUsers"
                               @click="$emit('onRestoreUser', props.row.id)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span class="pl-1">{{ $t('network-module.restore') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canHaveChildUsers(users[props.index].usertype)"
                :disabled="isProcessing"
                @click="$emit('onCreateUser', props.index)"
              >
                <feather-icon icon="UserPlusIcon" />
                <span class="pl-1">{{ $t('actions.add-user') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isHighLevel"
                @click="$emit('onResetPassword', props.index)"
              >
                <feather-icon icon="LockIcon" />
                <span class="pl-1">{{ $t('actions.reset-password') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isATeacher(users[props.index].usertype) && isSchoolUser"
                @click="$emit('onShowBlockedCourses', users[props.index].id)"
              >
                <feather-icon icon="ShieldIcon" />
                <span class="pl-1">{{ $t('actions.block-courses') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="AUTH_USER().user_level <= 7"
                :disabled="isProcessing"
                @click="$emit('onViewUserRelations', users[props.index].id)"
              >
                <feather-icon icon="UserPlusIcon" />
                <span class="pl-1">{{ $t('actions.view-user-relations') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canCreateSubUsers(users[props.index].usertype)"
                :disabled="isProcessing"
                @click="$emit('onViewSubUsers', props.index)"
              >
                <feather-icon icon="UsersIcon" />
                <span class="pl-1">{{ $t('actions.show-subusers') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="$emit('onDeactivateUser', props.row)"
              >
                <feather-icon icon="UserPlusIcon" />
                <span class="pl-1">{{ $t('deactivate-user.deactivate') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else-if="props.column.field === 'email'">
          <span style="display:grid">
            <span>
              <feather-icon icon="MailIcon"
                            class="text-primary"
              /> {{ props.formattedRow.email }}
              <b-button v-b-tooltip.hover="$t('user-module.copy-email')"
                        variant="flat-success"
                        class="btn-icon copy-button"
                        :disabled="isProcessing"
                        @click="copyTextToClipboard(props.formattedRow.email)"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </span>
            <small
              v-if="
                users[props.index].parent_email &&
                  isAStudent(users[props.index].usertype) &&
                  users[props.index].parent_email.length > 0
              "
              class="d-block"
            >{{ $t("user-types.parent") }}:
              {{ users[props.index].parent_email }}</small>
          </span>
          <span v-if="props.row.phone"><feather-icon icon="PhoneIcon"
                                                     class="text-warning"
                                       /> {{ props.row.phone }}
            <b-button v-b-tooltip.hover="$t('user-module.copy-phone')"
                      variant="flat-success"
                      class="btn-icon copy-button"
                      :disabled="isProcessing"
                      @click="copyTextToClipboard(props.row.phone)"
            >
              <feather-icon icon="CopyIcon" />
            </b-button></span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="!search"
      class="d-flex justify-content-between flex-wrap"
    >
      <div class="d-flex align-items-center mb-0 mt-1 ml-0 pl-0">
        <b-form-select
          v-model="paginationData.perPage"
          :options="['3', '5', '10', '15', '20']"
          :disabled="isProcessing"
          class="mr-1"
          @input="$emit('onPaginationChange')"
        />
        <span class="text-nowrap">
          {{ $t("messages.of") }} {{ paginationData.totalItems }}
          {{ $t("user-module.users") }}
        </span>
      </div>
      <div>
        <b-pagination
          :value="1"
          :total-rows="paginationData.totalItems"
          :per-page="paginationData.perPage"
          :disabled="isProcessing"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value) => $emit('onPaginationChange', value)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import string from '@/utils/string'
import {
  BPagination,
  BFormSelect,
  BButton,
  BAvatar,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import {
  canCreateSubUsers,
  canHaveChildUsers,
  canAttachParentToStudent,
  canViewStudentReport,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
  USER_TYPE_PARENT,
  USER_TYPE_SUPER,
  USER_TYPE_SCHOOL,
} from '@/const/userType'
import Ripple from 'vue-ripple-directive'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import CardView from './CardView.vue'

export default {
  components: {
    BPagination,
    BFormSelect,
    BButton,
    VueGoodTable,
    BAvatar,
    BDropdown,
    BDropdownItem,
    CardView,
    BBadge,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    paginationData: {
      type: Object,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: false,
    },
    userType: {
      type: String,
      default: '',
    },
    isTrashedUsers: {
      type: Boolean,
      default: false,
    },
    showCardView: {
      type: Boolean,
      default: false,
    },
    showAllPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [],
      showPassword: [],
    }
  },
  computed: {
    self() {
      return getUserData()
    },
    isModernUI() {
      return (this.isATeacher(this.self.usertype) || this.isAParent(this.self.usertype)) && this.$store.state.appConfig.layout.ui === 'modern'
    },
    isHighLevel() {
      return isHighLevelUser()
    },
    isSchoolUser() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
    isASuper() {
      return this.self.usertype === USER_TYPE_SUPER
    },
  },
  watch: {
    userType() {
      this.assignTableColumns()
    },
  },
  mounted() {
    this.assignTableColumns()
  },
  methods: {
    getRandomVariant() {
      const a = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']
      return a[Math.floor(a.length * Math.random())]
    },
    assignTableColumns() {
      this.columns = this.userType !== USER_TYPE_STUDENT ? [
        {
          label: this.$i18n.tc('user-module.user-table.name'),
          field: 'fullname',
        },
        {
          label: `${this.$i18n.tc('user-module.user-table.email')} / ${this.$i18n.tc('actions.phone')}`,
          field: 'email',
        },
        {
          label: `${this.$i18n.tc('user-module.user-table.user-type')} / ${this.$i18n.tc('actions.password')}`,
          field: 'usertype',
        },
        {
          label: `${this.$i18n.tc('user-module.user-table.country')} / ${this.$i18n.tc('actions.language')}`,
          field: 'country',
        },
        {
          label: this.$i18n.tc('user-module.user-table.actions'),
          field: 'action',
          width: '268px',
          sortable: false,
        },
      ] : [
        {
          label: this.$i18n.tc('user-module.user-table.name'),
          field: 'fullname',
        },
        {
          label: `${this.$i18n.tc('user-module.user-table.email')} / ${this.$i18n.tc('actions.phone')}`,
          field: 'email',
        },
        {
          label: this.$i18n.tc('user-module.user-table.user-type'),
          field: 'usertype',
        },
        {
          label: this.$i18n.tc('user-module.user-table.country'),
          field: 'country',
        },
        {
          label: this.$i18n.tc('user-module.user-table.actions'),
          field: 'action',
          width: '268px',
          sortable: false,
        },
      ]
    },
    isAStudent(type) {
      return USER_TYPE_STUDENT === type
    },
    isATeacher(type) {
      return USER_TYPE_TEACHER === type
    },
    isAParent(type) {
      return USER_TYPE_PARENT === type
    },
    canAttachParentToStudent(type) {
      return canAttachParentToStudent(type)
    },
    canViewStudentReport(type) {
      return canViewStudentReport(type)
    },
    enumToString(val) {
      return string.enumToString(val)
    },
    canCreateSubUsers(usertype) {
      return canCreateSubUsers(usertype)
    },
    canHaveChildUsers(usertype) {
      return canHaveChildUsers(usertype)
    },
    canShowPassword(id) {
      const show = this.showPassword.find(a => a.id === id)
      return show && show.visible
    },
    updateShowPassword(id) {
      const show = this.showPassword.find(a => a.id === id)
      if (show) {
        this.showPassword = this.showPassword.map(obj => (obj.id === id ? { ...obj, visible: !show.visible } : obj))
      } else {
        this.showPassword.push({ id, visible: true })
      }
    },
    getInitials(name) {
      const names = name.split(' ')
      let initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    },
  },
}
</script>

<style>
.avatar-status-indicator{
  border-radius: 50%; width: 12px; height: 12px; position: absolute; right: 0; bottom: 0;
}
.avatar-online {
  background: #28c76f;
}
.avatar-offline {
  background: #82868b;
}
.nickname{
  font-size:14px;
  font-style:italic;
  margin-top:-16px;
}
.gec-user-lists .vgt-table {
  min-height: 250px;
}
</style>
