<template>
  <b-row class="users-module">
    <!-- filters and create new button -->
    <b-col
      md="12"
      class="md-d-flex md-justify-content-between"
    >
      <!-- Users Count -->
      <users-count
        v-if="isATeacher || isHighLevelUser"
        :users-count="usersCount"
        :is-loading="isProcessing"
      />

      <b-row class="p-1">
        <b-col
          lg="10"
          md="12"
          sm="12"
        >
          <b-row class="d-flex justify-content-start p-0">
            <b-col
              md="2"
              xs="2"
              sm="12"
              class="p-0 pr-md-1 mb-1 mb-md-0"
            >
              <b-form-select
                id="search-usertype"
                v-model="search.userType"
                :options="childrenUserTypesForSelect"
                :disabled="isDisplayingSubUsers || isProcessing"
                name="search-usertype"
                @change="onSearchUserTypeChange"
              />
            </b-col>
            <b-col
              xs="4"
              md="3"
              sm="12"
              class="p-0 pr-md-1"
            >
              <b-form-group>
                <b-form-input
                  v-model="search.searchTerm"
                  debounce="500"
                  :placeholder="$t('search')"
                  type="text"
                  class="d-inline-block"
                  :disabled="isProcessing"
                  @change="onSearchUserTypeChange"
                />
              </b-form-group>
            </b-col>
            <b-col md="2"
                   xs="2"
                   sm="12"
                   class="p-0 pl-1 pr-md-1 mb-1 mb-md-0"
            >
              <b-form-select
                id="search-usertype-filterBy"
                v-model="search.filterBy"
                :options="filterByType"
                :disabled="isProcessing"
                name="search-usertype-filterBy"
                @change="onSearchUserTypeChange"
              />
            </b-col>
            <b-col
              md="2"
              xs="2"
              sm="12"
              class="p-0 pl-1 pr-md-1 mb-1 mb-md-0"
            >
              <b-form-select
                id="search-usertype"
                v-model="search.onlineOflineuser"
                :options="userOnlineType"
                :disabled="isDisplayingSubUsers || isProcessing"
                name="search-usertype"
                @change="onSearchUserTypeChange"
              />
            </b-col>
            <b-col
              v-if="search.userType==='student'"
              md="3"
              xs="2"
              sm="12"
              class="p-0 pl-1 pr-md-1 mb-1 mb-md-0"
            >
              <span
                v-b-tooltip.hover="'School Filter'"
                class="pt-1"
              >
                <b-form-select
                  id="search-school"
                  v-model="search.school"
                  :options="schools"
                  :disabled="isDisplayingSubUsers || isProcessing"
                  name="search-school"
                  @change="onSearchUserTypeChange($event,'school')"
                >
                  <template #first>
                    <b-form-select-option selected
                                          :value="null"
                                          disabled
                    >
                      -- {{ $t('user-module.user-table.select-school') }} --
                    </b-form-select-option>
                    <b-form-select-option :value="null">
                      {{ $t('user-module.user-table.all') }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </span>
            </b-col>
            <b-col
              v-if="search.userType==='student'"
              md="2"
              xs="2"
              sm="12"
              class="p-0 pl-1 pr-md-1 mb-1 mb-md-0"
            >
              <span
                v-b-tooltip.hover="'Class Filter'"
                class="pt-1"
              >
                <b-form-select
                  id="search-class"
                  v-model="search.class"
                  :options="classRooms"
                  :disabled="isDisplayingSubUsers || isProcessing"
                  name="search-class"
                  @change="onSearchUserTypeChange"
                >
                  <template #first>
                    <b-form-select-option selected
                                          :value="null"
                                          disabled
                    >
                      -- {{ $t('user-module.user-table.select-class') }} --
                    </b-form-select-option>
                    <b-form-select-option :value="null">
                      {{ $t('user-module.user-table.all') }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </span>
            </b-col>
            <b-col
              md="2"
              xs="2"
              sm="12"
              class="p-0 pl-1 pr-md-1 mb-1 mb-md-0"
            >
              <span
                v-b-tooltip.hover="$t('account-setting-general.access-type')"
                class="pt-1"
              >
                <b-form-select
                  id="search-class"
                  v-model="search.accessType"
                  :options="filterByAccessType"
                  :disabled="isDisplayingSubUsers || isProcessing"
                  name="search-class"
                  @change="onSearchUserTypeChange"
                />
              </span>
            </b-col>

          </b-row>
        </b-col>
        <b-col
          lg="2"
          md="12"
          sm="12"
          class="px-1"
        >
          <b-row class="md-d-flex align-items-center justify-content-between">
            <b-col md="3"
                   xs="3"
                   sm="3"
                   class="p-0 mr-lg-1"
            >
              <b-dropdown id="dropdown-1"
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
              >
                <template #button-content>
                  <span v-b-tooltip.hover="$t('user-module.user-table.export-import')"
                        class="bg-success text-white p-1 rounded"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      size="18"
                    />
                  </span>
                </template>
                <b-dropdown-item>
                  <b-button
                    v-if="!isParentUser(self.usertype)"
                    id="import-users"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :disabled="isProcessing"
                    variant="outline-success"
                    class="w-100"
                    @click="$emit('BulkExcelUpload')"
                  >
                    <feather-icon icon="UploadIcon" />  {{ $t("user-module.excel-upload") }}
                  </b-button></b-dropdown-item>
                <b-dropdown-item>
                  <b-button
                    v-if="!isParentUser(self.usertype)"
                    id="export-users"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :disabled="isProcessing"
                    variant="outline-success"
                    class="w-100"
                    @click="exportUsers"
                  >
                    <feather-icon icon="DownloadIcon" /> {{ $t("user-module.export-users") }}
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>

            <!-- Create User Button -->
            <b-col
              v-if="canDirectlyCreateUserOfType(self.usertype, search.userType) || isASuper"
              sm="8"
              xs="8"
              md="7"
              class="px-0"
            >
              <b-button
                v-if="!isParentUser(self.usertype)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :disabled="isProcessing"
                variant="outline-primary"
                class="mt-1 mt-md-0 w-100 px-0"
                @click="toggleCreateUserModal()"
              >
                {{ $t("user-module.create-user") }}
              </b-button>
            </b-col>
            <b-col
              v-else-if="isATeacher"
              sm="8"
              xs="8"
              md="7"
              class="px-0"
            >
              <b-button
                v-if="!isParentUser(self.usertype)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :disabled="isProcessing"
                variant="outline-primary"
                class="mt-1 mt-md-0 w-100 px-0"
                @click="toggleCreateUserModal()"
              >
                {{ $t("user-module.create-user") }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <!-- filters and create new button end -->

    <!-- Breadcrumb-->
    <b-col md="12 p-0">
      <b-breadcrumb>
        <b-breadcrumb-item
          v-for="(item, index) in getBreadcrumbItems"
          :key="'breadcrumb_' + item.text"
          :active="item.active"
          :class="['breadcrumb-item', item.active ? 'active' : null]"
          :disabled="isProcessing"
          @click="isProcessing ? null : onBreadCrumbItemClick(item, index)"
        >
          <feather-icon
            v-if="item.type == 'user'"
            icon="UsersIcon"
          />
          {{ item.text }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </b-col>
    <!-- list of users table -->
    <b-col md="12 mt-2">
      <b-row>
        <b-col md="6">
          <p>
            <strong>{{
                      isDisplayingSubUsers
                        ? $t("user-types." + currentUser.usertype)
                        : $t("user-types." + search.userType)
                    }}
              {{ $t("user-module.users") }}</strong>
            <span v-if="currentUser.id !== self.id">
              {{ $t("messages.of") }} <strong>{{ currentUser.fullname }}</strong>
            </span>
          </p>
        </b-col>
        <b-col
          md="6"
          class="d-flex justify-content-end"
        >
          <div class="d-flex justify-content-end mr-2">
            <span v-if="showAllPassword">
              {{ $t("user-module.user-table.hide-all-password") }}
            </span>
            <span v-else>
              {{ $t("user-module.user-table.show-all-password") }}
            </span>
            <b-form-checkbox
              v-model="showAllPassword"
              checked="true"
              class="custom-control-primary ml-2"
              name="check-button"
              switch
              :disabled="isProcessing"
            >
              <span class="switch-icon-left">
                <feather-icon icon="EyeOffIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="EyeIcon" />
              </span>
            </b-form-checkbox>
          </div>
          <div class="d-flex justify-content-end mr-2">
            <span v-if="showCardView">{{ $t("user-module.table-view") }}</span>
            <span v-else>{{ $t("user-module.card-view") }}</span>
            <b-form-checkbox
              v-model="showCardView"
              checked="true"
              class="custom-control-primary ml-2"
              name="check-button"
              switch
              :disabled="isProcessing"
            >
              <span class="switch-icon-left">
                <feather-icon icon="AlignJustifyIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="GridIcon" />
              </span>
            </b-form-checkbox>
          </div>
          <div v-if="canCreateSubUsers(currentUser.usertype)"
               class="d-flex justify-content-end"
          >
            <span class="mr-1">{{ $t("user-module.display-subusers") }}</span>
            <b-form-checkbox
              v-model="isDisplayingSubUsers"
              checked="true"
              class="custom-control-primary"
              name="check-button"
              switch
              :disabled="isProcessing"
              @change="onDisplaySubUserSwitch"
            />
          </div>
        </b-col>
      </b-row>

      <users-table
        :is-processing="isProcessing"
        :pagination-data="paginationData"
        :users="rows"
        :search="search.searchTerm"
        :is-trashed-users="isTrashedUsers"
        :user-type="search.userType"
        :show-card-view="showCardView"
        :show-all-password="showAllPassword"
        @onPaginationChange="getUsers"
        @onEditUser="editUser"
        @onDeleteUser="setUserBeingDeleted"
        @onRestoreUser="userID => userIdBeingRestored = userID"
        @onViewChildUsers="viewChildUsers"
        @onViewSubUsers="viewSubUsers"
        @onAttachParent="attachParentToStudent"
        @onCreateUser="createUserFor"
        @onViewStudentClassrooms="viewStudentClassrooms"
        @onResetPassword="onResetPassword"
        @onViewUserRelations="viewUserRelations"
        @onShowBlockedCourses="val => userIdToShowBlockedCourse = val"
        @onDeactivateUser="deactivateUserConfirmation"
      />

      <!-- list of users table -->
    </b-col>

    <!-- create/edit user model -->
    <b-modal
      id="modal-create-user"
      ref="modal-create-user"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      :click-to-close="false"
      :no-close-on-backdrop="true"
      modal-class="modal-primary"
      centered
      :title="getCreateUserModalTitle"
      no-close-on-esc
    >
      <b-tabs>
        <b-tab :title="createUserTabs[2]">
          <b-col md="12">
            <!-- form -->
            <validation-observer
              v-slot="{ invalid }"
              ref="createUserForm"
            >
              <b-form
                class="auth-login-form"
                @submit.prevent="isEdit ? updateUser():createUser()"
              >
                <button
                  type="submit"
                  class="d-none"
                />
                <b-row>
                  <!-- first_name -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('first-name')"
                      label-for="register-first_name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('first-name')"
                        rules="required|max:50"
                        vid="firstname"
                      >
                        <b-form-input
                          id="register-first_name"
                          v-model="newUser.firstname"
                          name="register-first_name"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('first-name')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- first_name -->

                  <!-- lastname -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('last-name')"
                      label-for="register-lastname"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('last-name')"
                        vid="lastname"
                        rules="required|max:50"
                      >
                        <b-form-input
                          id="register-lastname"
                          v-model="newUser.lastname"
                          name="register-lastname"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('last-name')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- lastname -->

                  <!-- Nickname -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('account-setting-general.nick-name')"
                      label-for="nickname"
                    >
                      <b-form-input
                        v-model="newUser.nickname"
                        name="nickname"
                        :placeholder="$t('account-setting-general.nick-name')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Nickname -->

                  <!-- email -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('email')"
                      label-for="login-email"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('email')"
                        vid="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="login-email"
                          v-model="newUser.email"
                          :state="errors.length > 0 ? false : null"
                          name="login-email"
                          placeholder="john@example.com"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- email -->

                  <!-- phone -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('phone')"
                      label-for="register-phone"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('phone')"
                        vid="phone"
                        :rules="{
                          required: !!newUser.phone_login,
                          digits: 10
                        }"
                      >
                        <b-form-input
                          id="register-phone"
                          v-model="newUser.phone"
                          name="register-phone"
                          :placeholder="$t('phone')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- phone -->

                  <!-- country -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('country')"
                      label-for="register-country"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('country')"
                        vid="country"
                        rules="required"
                      >
                        <b-form-select
                          id="register-country"
                          v-model="newUser.country"
                          :options="countries"
                          name="register-country"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- country -->

                  <!-- language -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('language')"
                      label-for="register-language"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('language')"
                        vid="language"
                        rules="required"
                      >
                        <b-form-select
                          id="register-language"
                          v-model="newUser.language"
                          :options="languages"
                          name="register-language"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- language -->

                  <!-- User Tag -->

                  <b-col md="6">

                    <b-form-group
                      :label="$t('labels.user-tags')"
                      label-for="tags"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('labels.user-tags')"
                        vid="tags"
                        rules=""
                      >
                        <b-form-select
                          id="tags"
                          v-model="newUser.tags"
                          :options="tags"
                          name="tags"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- User Tag -->

                  <!-- user-types -->
                  <b-col
                    v-if="
                      !isEdit && (self.id == creatingUserFor.id || isSchoolUser(creatingUserFor.usertype) || (isASuper && isNationalUser(creatingUserFor.usertype)))
                    "
                    md="6"
                  >
                    <b-form-group
                      :label="$t('usertype')"
                      label-for="user-type"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('usertype')"
                        vid="usertype"
                        rules="required"
                      >
                        <b-form-select
                          id="user-type"
                          v-model="newUser.usertype"
                          :disabled="isCreatingSubuser"
                          :options="getCreateUserOptions"
                          name="user-type"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="
                      AUTH_USER().usertype === 'teacher' && !isEdit
                    "
                    md="6"
                  >
                    <b-form-group
                      :label="$t('School')"
                      label-for="user-type"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('School')"
                        vid="usertype"
                        rules="required"
                      >
                        <v-select
                          v-model="newUser.school_ids"
                          :disabled="isCreatingSubuser"
                          :options="parentUserInfo"
                          :placeholder="$t('school-user')"
                          multiple
                          :reduce="(item) => item.value"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- user type -->

                  <b-col md="6">
                    <div class="d-flex justify-content-between">
                      <!-- is sub user -->
                      <b-form-group
                        :label="$t('labels.phone-login')"
                        label-for="phone-login"
                      >
                        <b-form-checkbox
                          v-model="newUser.phone_login"
                          class="custom-control-primary"
                          name="check-button"
                          switch
                          :disabled="isProcessing"
                        />
                      </b-form-group>
                      <!-- is sub user -->
                      <b-form-group
                        v-if="
                          !isEdit &&
                            creatingUserFor.usertype != USER_TYPE_STUDENT &&
                            canCreateSubUsers(self.usertype)"
                        :label="$t('labels.is-subuser')"
                        label-for="is-subuser"
                      >
                        <b-form-checkbox
                          :checked="newUser.usertype === creatingUserFor.usertype"
                          class="custom-control-primary"
                          name="check-button"
                          switch
                          :disabled="isProcessing"
                          @change="toggleUserTypeBeingCreated"
                        />
                      </b-form-group>
                      <!-- is sub user -->

                      <!-- verification email -->
                      <b-form-group
                        v-if="!isEdit"
                        :label="$t('labels.send-email-to-user')"
                        label-for="userVerification"
                      >
                        <b-form-checkbox
                          id="userVerification"
                          v-model="newUser.userVerification"
                          class="custom-control-primary"
                          value="1"
                          unchecked-value="0"
                          switch
                          :disabled="isProcessing"
                        />
                      </b-form-group>
                      <!-- verification email -->
                    </div>
                  </b-col>
                  <b-col v-if="newUser.usertype===USER_TYPE_NATIONAL"
                         cols="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('libraries')"
                      vid="libraries"
                      :rules="'required'"
                    >
                      <label>{{ $t('libraries') }}</label>
                      <v-select
                        v-model="newUser.libraries"
                        label="text"
                        :reduce="(item) => item.value"
                        multiple
                        :options="libraries"
                        :placeholder="$t('select-library')"
                        :clearable="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-col
                  md="12"
                  class="mt-2 d-flex justify-content-end"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    :disabled="isProcessing || invalid"
                    @click="isEdit ? updateUser():createUser()"
                  >
                    <b-spinner
                      v-show="isProcessing"
                      small
                    />
                    {{ $t('actions.submit') }}
                  </b-button>
                </b-col>
              </b-form>
            </validation-observer>
          </b-col>
        </b-tab>
        <b-tab
          v-if="!isSuperUser(creatingUserFor.usertype) && (self.id !== creatingUserFor.id) && ( (isSuperUser(self.usertype)|| self.usertype!==getFirstParentOfUser(creatingUserFor.usertype))) && !isParentUser(creatingUserFor.usertype) && !isEdit"
          :title="createUserTabs[0]"
        >
          <b-col md="12">
            <validation-observer
              v-slot="{ invalid }"
              ref="assignparentform"
            >
              <b-form
                class="auth-login-form"
                @submit.prevent="assignUsers('parent')"
              >
                <b-row>
                  <b-col
                    md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Users"
                      vid="users"
                      :rules="'required'"
                    >
                      <v-select
                        v-model="selectedParentUsers"
                        :multiple="!isStudentUser(creatingUserFor.usertype) && getUserTypeLevel(creatingUserFor.usertype) >= 7"
                        :reduce="(item) => item.code"
                        :options="parentUsersForSelect"
                        :clearable="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="12"
                    class="mt-2 d-flex justify-content-end"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      :disabled="isProcessing || invalid"
                      @click="assignUsers('parent')"
                    >
                      <b-spinner
                        v-show="isProcessing"
                        small
                      />
                      {{ $t('actions.submit') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </b-tab>

        <b-tab
          v-if="!isStudentUser(creatingUserFor.usertype) && !isEdit && isHighLevelUser"
          :title="createUserTabs[1]"
        >
          <b-col md="12">
            <validation-observer
              v-slot="{ invalid }"
              ref="assignchildform"
            >
              <b-form
                class="auth-login-form"
                @submit.prevent="assignUsers('child')"
              >
                <b-row>
                  <b-col
                    md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="User"
                      :rules="'required'"
                      vid="user"
                    >
                      <v-select
                        v-model="selectedChildrenUsers"
                        :reduce="(item) => item.code"
                        multiple
                        :options="childrenUsersForSelect"
                        :clearable="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-col
                  md="12"
                  class="mt-2 d-flex justify-content-end"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    :disabled="isProcessing || invalid"
                    @click="assignUsers('child')"
                  >
                    <b-spinner
                      v-show="isProcessing"
                      small
                    />
                    {{ $t('actions.submit') }}
                  </b-button>
                </b-col>
              </b-form>
            </validation-observer>
          </b-col>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{}">
        <span />
      </template>
    </b-modal>
    <!-- create/edit user model -->

    <!-- delete user modal -->
    <delete-modal
      :title="deletePermanently ? $t('users-module.delete-user-permanently-title'): $t('actions.delete-user')"
      :show="indexOfUserBeingDeleted != null"
      :on-delete="deleteUser"
      :is-processing="isProcessing"
      :sub-title="deletePermanently ? $t('users-module.delete-user-permanently-description'):$t('questions.delete-user')"
      @close="deleteModalClosed"
    />
    <!-- restore user modal -->
    <delete-modal
      :title="$t('network-module.restore')"
      varient="primary"
      :show="!!userIdBeingRestored"
      :on-delete="restoreUser"
      :is-processing="isProcessing"
      variant
      :sub-title="$t('questions.restore-user')"
      @close="userIdBeingRestored = null"
    />

    <!-- reset password modal -->
    <b-modal
      id="modal-reset-password"
      ref="modal-reset-password"
      :title="
        indexOfUserBeingEdited != null
          ? `${$t('actions.reset-password')} (${rows[indexOfUserBeingEdited].fullname})`
          : $t('actions.reset-password')
      "
      ok-title="Submit"
      ok-only
      hide-footer
      @hidden="resetModal"
    >
      <b-overlay :show="isProcessing">
        <!-- form -->
        <b-form @submit.prevent="resetPassword">
          <validation-observer ref="resetPasswordForm">
            <!-- new password -->
            <b-form-group
              label-for="account-new-password"
              :label="$t('new-password')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('new-password')"
                vid="password"
                rules="required|min:3"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="resetPw.newPassword"
                    :type="resetPw.newPasswordType"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="password"
                    name="password"
                    autocomplete="off"
                    :placeholder="$t('new-password')"
                  />

                  <b-input-group-append
                    is-text
                    class="eye-icon"
                  >
                    <feather-icon
                      :icon="newPasswordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePassword('newPasswordType')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--/ new password -->

            <!-- confirm password -->
            <b-form-group
              label-for="account-confirm-password"
              :label="$t('password-confirmation')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('password-confirmation')"
                vid="password_confirmation"
                rules="required|min:3|confirmed:password"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="account-confirm-password"
                    v-model="resetPw.confirmPassword"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="password"
                    :type="resetPw.confirmPasswordType"
                    name="confirm-password"
                    :placeholder="$t('password-confirmation')"
                  />

                  <b-input-group-append
                    is-text
                    class="eye-icon"
                  >
                    <feather-icon
                      :icon="confirmPasswordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePassword('confirmPasswordType')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
            <!--/ confirm password -->

            <!-- buttons -->
            <div class="d-flex justify-content-between mb-1">
              <b-button
                variant="outline-primary"
                class="generate-btn mt-1 mr-1"
                @click.prevent="generatePassword"
              >
                {{ $t('actions.generate-random-password') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                class="mt-1"
                @click="resetPassword"
              >
                {{ $t('actions.submit') }}
              </b-button>
            </div>
            <!--/ buttons -->
          </validation-observer>
        </b-form>
      </b-overlay>
    </b-modal>
    <!-- reset password modal -->

    <!-- User Deactivation Modal -->
    <b-modal
      :visible="showDeactivationModal"
      :title="deactivateModalTitle"
      ok-only
      @close="resetDeactivationState"
      @hide="resetDeactivationState"
    >
      <b-overlay>
        <h4 class="mb-1">
          {{ $t('deactivate-user.deactivate-sub-title') }}
        </h4>
        <b-form-radio
          v-model="deactivateType"
          name="deactivateType"
          value="partial_deactivate"
        >
          <h5 class="mb-0">
            {{ $t('deactivate-user.partial-deactivate') }}
          </h5>
          <p>
            {{ $t('deactivate-user.partial-deactivate-info') }}
          </p>
        </b-form-radio>
        <b-form-radio
          v-model="deactivateType"
          name="deactivateType"
          value="full_deactivate"
        >
          <h5 class="mb-0">
            {{ $t('deactivate-user.full-deactivate') }}
          </h5>
          <p>
            {{ $t('deactivate-user.partial-deactivate-info') }}
          </p>
        </b-form-radio>
      </b-overlay>
      <template #modal-footer="">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-if="deactivateType === 'partial_deactivate' || deactivateType === 'full_deactivate'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
              class="mr-1"
              :disabled="isProcessingDeactivate"
              @click="activateUser"
            >
              <b-spinner
                v-show="isProcessingDeactivate"
                small
              />
              {{ $t('deactivate-user.activate') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="deactivateType === null || isProcessingDeactivate"
              @click="deactivateUser"
            >
              <b-spinner
                v-show="isProcessingDeactivate"
                small
              />
              {{ $t('confirm') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- User Deactivation Modal -->

    <excel-upload />
    <BlockedCourses v-if="userIdToShowBlockedCourse"
                    :user-id="userIdToShowBlockedCourse"
                    @close="userIdToShowBlockedCourse = null"
    />
    <user-relation ref="userRelation"
                   :get-create-user-modal-title="getCreateUserModalTitle"
    />
  </b-row>
</template>
<script>
import {
  BFormInput,
  BRow,
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormSelect,
  BFormGroup,
  BFormSelectOption,
  VBToggle,
  BSpinner,
  VBModal,
  BBreadcrumb,
  BBreadcrumbItem,
  BTabs,
  BTab,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BDropdown,
  VBTooltip,
  BDropdownItem,
  BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import string from '@/utils/string'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import vSelect from 'vue-select'

import {
  canCreateSubUsers,
  canHaveChildUsers,
  canDirectlyCreateUserOfType,
  getChildUserTypesOfType,
  canCreateUsers,
  getFirstChildOfUser,
  getFirstParentOfUser,
  getUserTypeLevel,
  USER_TYPE_PARENT,
  USER_TYPE_SCHOOL,
  USER_TYPE_STUDENT,
  USER_TYPE_SUPER,
  USER_TYPE_NATIONAL,
  USER_TYPE_INTERNATIONAL,
  USER_TYPE_TEACHER,
} from '@/const/userType'
import { userTags } from '@/const/tags'
// import vSelect from 'vue-select'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import i18n from '@/libs/i18n'
import exportFromJSON from 'export-from-json'
import useApollo from '@/@core/graphql/useApollo'
import RegisteredCountries from '@/const/registered-country'
import UsersCount from './components/UsersCount.vue'
import UsersTable from './components/Table.vue'
import ExcelUpload from './components/ExcelUpload.vue'
import UserRelation from './components/UserRelation.vue'
import BlockedCourses from './components/BlockedCourses.vue'

export default {
  components: {
    BButton,
    DeleteModal,
    BFormCheckbox,
    BFormInput,
    BRow,
    BFormSelect,
    BFormGroup,
    BFormSelectOption,
    UsersTable,
    BCol,
    BBreadcrumb,
    vSelect,
    BBreadcrumbItem,
    BForm,
    ValidationProvider,
    BTabs,
    BTab,
    ValidationObserver,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    ExcelUpload,
    UserRelation,
    BDropdown,
    BDropdownItem,
    UsersCount,
    BlockedCourses,
    BFormRadio,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      USER_TYPE_SUPER,
      showCardView: false,
      newUser: {},
      tags: userTags,
      countries: RegisteredCountries,
      languages: [
        { value: 'English', text: 'English' },
        { value: 'Spanish', text: 'Español,' },
        { value: 'Korean', text: '한국어' },
        { value: 'Japanese', text: '日本語' },
        { value: 'Vietnamese', text: 'Tiếng Việt' },
        { value: 'Hindi', text: 'हिन्दी, हिंदी' },
      ],
      rows: [],
      userTypes: [],
      searchTerm: '',
      search: {
        userType: null,
        onlineOflineuser: '',
        class: null,
        school: null,
        filterBy: 'email',
        searchTerm: null,
        accessType: null,
      },
      isProcessing: false,
      indexOfUserBeingEdited: null,
      indexOfUserBeingDeleted: null,
      isDisplayingSubUsers: false,
      isCreatingSubuser: false,
      deletePermanently: false,
      selectedChildrenUsers: [],
      selectedParentUsers: [],
      parentUserInfo: [],
      self: getUserData(),
      childrenUsersForSelect: [],
      parentUsersForSelect: [],

      // history of users being viewed
      viewingHistory: [
        {
          user: getUserData(),
          showSubUser: false,
          userType: getFirstChildOfUser(getUserData().usertype),
        },
      ],
      paginationData: {
        currentPage: 1,
        perPage: 10,
        totalItems: 0,
        lastPage: 0,
      },
      schools: [],
      classRooms: [],
      // data to link new user with a user
      // by default linked to self
      creatingUserFor: getUserData(),
      searchUsers: [],
      userOnlineType: [
        {
          text: i18n.tc('user-module.user-table.all'),
          value: '',
        },
        {
          text: i18n.tc('user-module.user-table.online'),
          value: 'online',
        },
        {
          text: i18n.tc('user-module.user-table.offline'),
          value: 'offline',
        },
        {
          text: i18n.tc('user-module.user-table.trashed'),
          value: 'trashed',
        },
      ],
      filterByType: [
        {
          text: 'Id',
          value: 'id',
        },
        {
          text: i18n.tc('user-module.user-table.email'),
          value: 'email',
        },
        {
          text: i18n.tc('user-module.user-table.phone'),
          value: 'phone',
        },
        {
          text: i18n.tc('user-module.user-table.first-name'),
          value: 'firstname',
        },
        {
          text: i18n.tc('user-module.user-table.last-name'),
          value: 'lastname',
        },
      ],
      filterByAccessType: [
        {
          text: i18n.tc('actions.all'),
          value: null,
        },
        {
          text: i18n.tc('deactivate-user.partially-deactivated'),
          value: 'partial_deactivate',
        },
        {
          text: i18n.tc('deactivate-user.fully-deactivated'),
          value: 'full_deactivate',
        },
      ],
      resetPw: {
        newPassword: '',
        confirmPassword: '',
        newPasswordType: 'password',
        confirmPasswordType: 'password',
      },
      canDirectlyCreateUserOfType,
      userIdBeingRestored: null,
      getUserTypeLevel,
      showAllPassword: false,
      libraries: [],
      userCountParentId: getUserData().id,
      userIdToShowBlockedCourse: null,
      showDeactivationModal: false,
      deactivateModalTitle: null,
      deactivateType: null,
      deactivateUserId: null,
      isProcessingDeactivate: false,
      usersCount: [],
    }
  },
  computed: {
    isHighLevelUser() {
      return isHighLevelUser()
    },
    isASuper() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    // selectedUserTypeLevel() {
    //   return getUserTypeLevel(this.search.userType)
    // },
    isTrashedUsers() {
      return this.search.onlineOflineuser === 'trashed'
    },
    createUserTabs() {
      return [
        `${i18n.tc('users-module.assign')} ${i18n.tc(`user-types.${getFirstParentOfUser(this.creatingUserFor.usertype)}`)} ${i18n.tc('user')}`,
        `${i18n.tc('users-module.assign')} ${i18n.tc(`user-types.${getFirstChildOfUser(this.creatingUserFor.usertype)}`)} ${i18n.tc('user')}`,
        `${i18n.tc(`actions.${this.isEdit ? 'update' : 'create'}`)} ${i18n.tc(`user-types.${this.newUser.usertype}`)} ${i18n.tc('user')}`,
      ]
    },
    USER_TYPE_STUDENT() {
      return USER_TYPE_STUDENT
    },
    USER_TYPE_NATIONAL() {
      return USER_TYPE_NATIONAL
    },
    currentUser() {
      return this.viewingHistory[this.viewingHistory.length - 1].user
    },
    getCreateUserModalTitle() {
      return `${i18n.tc('action')} ${i18n.tc('for')} ${this.creatingUserFor.fullname}`
    },
    isUpdatingSubuser() {
      return this.rows[this.indexOfUserBeingEdited]
        ? this.rows[this.indexOfUserBeingEdited].usertype
            === getUserData().usertype
        : false
    },
    childrenUserTypesForSelectedUser() {
      return getChildUserTypesOfType(this.creatingUserFor.usertype).map(
        e => ({
          value: e,
          text: string.enumToString(e),
        }),
      )
    },
    getCreateUserOptions() {
      return canCreateUsers(this.creatingUserFor.usertype).map(
        e => ({
          value: e,
          text: this.$i18n.tc(string.enumToString(e)),
        }),
      )
    },
    childrenUserTypes() {
      return this.userTypes.map(e => ({
        value: e,
        text: string.enumToString(e),
      }))
    },
    childrenUserTypesForSelect() {
      if (this.currentUser.id === this.self.id) {
        return this.userTypes.map(e => ({
          value: e,
          text: i18n.tc(`user-types.${e}`),
        }))
      }
      return this.userTypes.map((e, index) => ({
        disabled: this.userTypes.indexOf(this.currentUser.usertype) >= index,
        value: e,
        text: string.enumToString(e),
      }))
    },
    getBreadcrumbItems() {
      const breadCrumbItems = []
      this.viewingHistory.forEach((element, index) => {
        if (element.user.id === this.self.id) {
          breadCrumbItems.push({
            text: i18n.tc('user-module.users'),
            active: index === this.viewingHistory.length - 1,
            type: 'user',
          })
        } else {
          breadCrumbItems.push({
            text: element.user.fullname,
            active: index === this.viewingHistory.length - 1,
            type: 'sub-user',
          })
        }
      })

      return breadCrumbItems
    },
    isEdit() {
      return this.newUser.id != null
    },
    newPasswordToggleIcon() {
      return this.resetPw.newPasswordType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmPasswordToggleIcon() {
      return this.resetPw.confirmPasswordType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  watch: {
    search() {
      this.cacheData()
    },
  },
  mounted() {
    this.clearNewUser()
    this.getUserTypes()
    if (!localStorage.getItem('users-module')) {
      this.getUsers()
    }
    this.getDataFromCache()
    this.getSchoolUsers()
    if ([USER_TYPE_SUPER, USER_TYPE_INTERNATIONAL].includes(this.AUTH_USER().usertype)) {
      this.getLibrary()
    }
    this.$nextTick(() => {
      this.getUsersCount()
    })
  },
  methods: {
    viewUserRelations(userId, userType = null, userAddType = 'parent') {
      console.log('user type', userType, userAddType)
      this.$refs.userRelation.viewUserRelations(userId, userType, userAddType)
    },
    restoreUser() {
      this.isProcessing = true
      useJwt.restoreUser(this.userIdBeingRestored).then(response => {
        this.getUsers()
        this.showSuccessMessage(response)
        this.userIdBeingRestored = null
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    togglePassword(type) {
      this.resetPw[type] = this.resetPw[type] === 'password' ? 'text' : 'password'
    },
    cacheData() {
      const data = {
        rows: this.rows,
        viewingHistory: this.viewingHistory,
        search: this.search,
        isDisplayingSubUsers: this.isDisplayingSubUsers,
      }

      localStorage.setItem('users-module', JSON.stringify(data))
    },
    getDataFromCache() {
      let data = localStorage.getItem('users-module')
      if (!data) return
      data = JSON.parse(data)
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          this[key] = data[key]
        }
      }
      this.getUsers()
    },
    getFirstParentOfUser(type) {
      return getFirstParentOfUser(type)
    },
    isSuperUser(type) {
      return type === USER_TYPE_SUPER
    },
    isSchoolUser(type) {
      return type === USER_TYPE_SCHOOL
    },
    isNationalUser(type) {
      return type === USER_TYPE_NATIONAL
    },
    isParentUser(type) {
      return type === USER_TYPE_PARENT
    },
    isStudentUser(type) {
      return type === USER_TYPE_STUDENT
    },
    deleteModalClosed() {
      this.indexOfUserBeingDeleted = null
      this.deletePermanently = false
    },
    viewStudentClassrooms(data) {
      this.$router.push({
        name: `${this.self.usertype}-classes`,
        query: { user_id: data.id, usertype: data.usertype },
      })
    },
    handleSubmit() {
      if (this.isAssigningUsers) {
        this.attachSelectedUsers()
      } else if (this.isEdit) this.updateUser()
      else this.createUser()
    },
    assignUsers(type) {
      this.$refs[`assign${type}form`].validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt
            .attachChildUsers({
              // eslint-disable-next-line no-nested-ternary, valid-typeof
              child_id: type === 'parent' ? this.creatingUserFor.id : this.selectedChildrenUsers,
              // eslint-disable-next-line no-nested-ternary, valid-typeof
              parent_id: type === 'parent' ? (typeof this.selectedParentUsers !== 'array' ? [this.selectedParentUsers] : this.selectedParentUsers) : this.creatingUserFor.id,
              child_type: type === 'parent' ? this.creatingUserFor.usertype : getFirstChildOfUser(this.creatingUserFor.usertype),
              parent_type: type === 'parent' ? getFirstParentOfUser(this.creatingUserFor.usertype) : this.creatingUserFor.usertype,
            })
            .then(response => {
              this.$refs['modal-create-user'].hide()
              this.clearNewUser()
              this.selectedChildrenUsers = []
              this.selectedParentUsers = []
              this.getUsers()
              this.showSuccessMessage(response)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    getAllChildUsers() {
      useJwt.getAllChildUsers(this.newUser.usertype, this.creatingUserFor.id).then(response => {
        const { data } = response.data
        this.childrenUsersForSelect = data.users.map(e => ({
          code: e.id,
          label: `${e.firstname} ${e.lastname}: ${e.email}`,
        }))
        this.selectedChildrenUsers = data.existing_users.map(e => e.id)
      })
    },
    getAllParentUsers(usertype = this.creatingUserFor.usertype) {
      useJwt.getAllParentUsers(getFirstParentOfUser(usertype), this.creatingUserFor.id).then(response => {
        const { data } = response.data
        this.parentUsersForSelect = data.users.map(e => ({
          code: e.id,
          label: `${e.firstname} ${e.lastname}: ${e.email}`,
        }))
        this.selectedParentUsers = data.existing_users.map(e => e.id)
        this.parentUserInfo = data.existing_users.map(e => ({ value: e.id, label: `${e.firstname} ${e.lastname}` }))
      })
    },
    toggleUserTypeBeingCreated(value) {
      if (value) {
        this.newUser.usertype = this.creatingUserFor.usertype
        this.isCreatingSubuser = true
      } else {
        this.isCreatingSubuser = false
        this.newUser.usertype = getFirstChildOfUser(
          this.creatingUserFor.usertype,
        )
      }
    },
    onSearchUserTypeChange(value, type = null) {
      if (type === 'school' && this.search.school) {
        this.search.class = null
        this.getClassrooms()
      }
      this.viewingHistory[this.viewingHistory.length - 1].userType = value
      this.getUsers()
      this.getUsersCount()
    },
    onDisplaySubUserSwitch(value) {
      this.viewingHistory[this.viewingHistory.length - 1].showSubUser = value
      this.getUsers()
    },
    onBreadCrumbItemClick(item, index) {
      if (index === this.viewingHistory.length - 1) return
      this.viewingHistory.splice(index + 1, this.viewingHistory.length)

      this.userCountParentId = this.viewingHistory[this.viewingHistory.length - 1].user.id
      this.isDisplayingSubUsers = this.viewingHistory[this.viewingHistory.length - 1].showSubUser
      this.search.userType = this.viewingHistory[this.viewingHistory.length - 1].userType
      this.getUsers()
    },
    createUserFor(index) {
      this.clearNewUser()
      this.creatingUserFor = this.rows[index]
      this.newUser.usertype = getFirstChildOfUser(
        this.creatingUserFor.usertype,
      )
      if (this.newUser.usertype) this.getAllChildUsers()
      if (this.isATeacher) {
        this.getAllParentUsers(USER_TYPE_TEACHER)
      } else if (getFirstParentOfUser(this.creatingUserFor.usertype)) this.getAllParentUsers()
      this.$refs['modal-create-user'].show()
    },
    onEditModalClose() {
      this.isCreatingSubuser = false
    },
    canCreateSubUsers(usertype) {
      return canCreateSubUsers(usertype)
    },
    canHaveChildUsers(usertype) {
      return canHaveChildUsers(usertype)
    },
    setUserBeingDeleted(index) {
      this.indexOfUserBeingDeleted = index
      this.deletePermanently = this.isTrashedUsers
    },
    enumToString(val) {
      return string.enumToString(val)
    },
    clearNewUser() {
      this.newUser = {
        usertype:
          this.childrenUserTypes.length > 0
            ? this.childrenUserTypes[0].value
            : null,
        email: '',
        id: null,
        first_name: '',
        lastname: '',
        country: this.countries.length > 0 ? this.countries[0].value : null,
        language: this.languages.length > 0 ? this.languages[0].value : null,
        phone: null,
        tags: null,
        libraries: [],
        phone_login: 0,
      }
      if (this.isCreatingSubuser) {
        this.newUser.usertype = getUserData().usertype
      }
    },
    toggleCreateUserModal(usertype = 'user') {
      this.indexOfUserBeingEdited = null
      this.isCreatingSubuser = usertype !== 'user'
      this.creatingUserFor = this.self
      this.clearNewUser()
      if (this.search.userType) {
        this.newUser.usertype = this.search.userType
      }
      this.$refs['modal-create-user'].toggle()
      this.getAllChildUsers()
      this.getAllParentUsers()
    },
    getUserTypes() {
      this.userTypes = getChildUserTypesOfType(this.self.usertype)
      const [zero] = this.userTypes
      this.newUser.usertype = zero
      if (!this.search.userType) this.search.userType = zero
    },
    getPermissions() {
      // useJwt
      //   .getPermissionsForUserType(getUserData().usertype)
      //   .then(response => {
      //     this.permissions = response.data.data
      //   })
    },

    viewChildUsers(index, userType = null) {
      this.isDisplayingSubUsers = false
      this.userCountParentId = this.rows[index].id
      this.enterSubUsersMode(index, userType)
    },
    attachParentToStudent(index) {
      this.clearNewUser()
      this.creatingUserFor = this.rows[index]
      this.getAllChildUsers()
      this.getAllParentUsers()
      this.newUser.usertype = USER_TYPE_PARENT
      this.$refs['modal-create-user'].show()
    },
    enterSubUsersMode(index, userType = null) {
      this.viewingHistory.push({
        user: this.rows[index],
        userType: userType || getFirstChildOfUser(this.rows[index].usertype),
        showSubUser: this.isDisplayingSubUsers,
      })
      this.paginationData.currentPage = 1
      this.search.userType = userType || getFirstChildOfUser(this.currentUser.usertype)
      this.getUsers()
    },
    viewSubUsers(index) {
      this.isDisplayingSubUsers = true
      this.enterSubUsersMode(index)
    },

    editUser(index) {
      this.$refs['modal-create-user'].toggle()
      this.indexOfUserBeingEdited = index
      this.newUser = { ...this.rows[index], ...{} }
      this.creatingUserFor = this.self
    },
    onResetPassword(index) {
      this.indexOfUserBeingEdited = index
      this.$nextTick(() => {
        this.$refs['modal-reset-password'].toggle()
      })
    },
    advancedSearch() {
      this.isProcessing = true
      if (!this.searchTerm.trim()) {
        this.getUsers()
        return
      }
      useJwt.searchForUsers({
        params: {
          email: this.searchTerm,
          type: this.search.userType,
          withTrashed: this.search.onlineOflineuser === 'trashed' ? true : null,
        },
      }).then(response => {
        this.rows = response.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getSearchParam(page, perPage = this.paginationData.perPage) {
      const params = {
        id: this.currentUser.id !== this.self.id ? this.currentUser.id : null,
        page,
        perPage,
        onlineStatus: this.search.onlineOflineuser,
        schoolId: this.search.school,
        classId: this.search.class,
        withTrashed: this.isTrashedUsers ? true : null,
      }
      if (!this.isDisplayingSubUsers) {
        params.usertype = this.search.userType
      } else {
        params.subuser = true
      }
      if (this.search.searchTerm && this.search.searchTerm.trim()) {
        params.searchTerm = this.search.searchTerm
        params.filterBy = this.search.filterBy ? this.search.filterBy : 'email'
      }
      // if (this.search.userType === 'student') {
      //   params.accessType = this.search.accessType
      // }
      params.accessType = this.search.accessType
      return params
    },
    getUsers(page = this.paginationData.currentPage) {
      // if (this.searchTerm && this.searchTerm.trim()) {
      //   this.advancedSearch()
      //   return
      // }
      if (this.search.searchTerm) {
        // eslint-disable-next-line no-param-reassign
        page = 1
      }
      this.isProcessing = true
      const params = this.getSearchParam(page)
      useJwt
        .getUsers({
          params,
        })
        .then(response => {
          const responseData = response.data.data
          this.rows = responseData
          this.assignPaginationData(response.data.pagination)
          this.cacheData()
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    assignPaginationData(data) {
      this.paginationData = {
        currentPage: data.current_page,
        perPage: data.per_page,
        totalItems: data.total,
        lastPage: data.last_page,
      }
    },

    createUser() {
      this.$refs.createUserForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const requestData = this.newUser
          requestData.user_id = (this.self.id !== this.creatingUserFor.id || this.newUser.usertype === getFirstChildOfUser(this.creatingUserFor.usertype)) ? this.creatingUserFor.id : null;
          (this.isCreatingSubuser
            ? useJwt.createSubUser(requestData)
            : useJwt.createUser(
              this.creatingUserFor.usertype === USER_TYPE_STUDENT,
              this.newUser,
            )
          )
            .then(response => {
              if (this.newUser.usertype === USER_TYPE_STUDENT) this.createDefaultClassroom(response.data?.data?.id)
              this.$refs['modal-create-user'].hide()
              this.clearNewUser()
              this.getUsers()
              this.showSuccessMessage(response)
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.createUserForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    createDefaultClassroom(id) {
      const params = {
        name: `${this.newUser.firstname} private class`,
        is_default: 1,
        created_by: id,
      }
      useJwt.createClass(params).then(response => {
        const cls = response.data?.data
        this.addStudentToClass(cls.id, id)
      })
    },
    addStudentToClass(classId, id) {
      const params = {
        students: id,
      }
      useJwt.addStudentToClass(
        classId,
        params,
      )
    },
    deleteUser(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.isProcessing = true

      const userBeingDeleted = this.rows[this.indexOfUserBeingDeleted]
      useJwt
        .deleteUser(userBeingDeleted.id, this.deletePermanently)
        .then(response => {
          this.getUsers()
          this.indexOfUserBeingDeleted = null
          this.deletePermanently = false
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
          this.deletePermanently = false
        })
    },
    updateUser() {
      this.$refs.createUserForm.validate().then(success => {
        if (success) {
          this.isProcessing = true;
          (this.isUpdatingSubuser
            ? useJwt.updateSubUser(this.newUser.id, this.newUser)
            : useJwt.updateUser(this.newUser.id, this.newUser)
          )
            .then(response => {
              this.$refs['modal-create-user'].hide()
              this.clearNewUser()
              this.getUsers()
              this.showSuccessMessage(response)
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.createUserForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    generatePassword() {
      const random = Math.random().toString(36).slice(2, 10)
      this.resetPw = {
        newPassword: random,
        confirmPassword: random,
        newPasswordType: 'text',
        confirmPasswordType: 'text',
      }
    },
    resetModal() {
      this.resetPw = {
        newPassword: '',
        confirmPassword: '',
        newPasswordType: 'password',
        confirmPasswordType: 'password',
      }
    },
    resetPassword() {
      this.$refs.resetPasswordForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt.resetUserPassword(this.rows[this.indexOfUserBeingEdited].id, {
            _method: 'put',
            data: this.resetPw.newPassword,
          }).then(response => {
            this.isProcessing = false
            this.$refs['modal-reset-password'].hide()
            this.showSuccessMessage(response)
            this.getUsers()
          }).catch(error => {
            this.$refs.resetPasswordForm.setErrors({
              password: error.response.data.errors,
              password_confirmation: error.response.data.errors,
            })
            this.isProcessing = false
            this.showErrorMessage(error.response?.data?.errors)
          })
        }
      })
    },
    exportUsers() {
      const fileName = 'students-list'
      const exportType = exportFromJSON.types.csv
      if (this.searchTerm && this.searchTerm.trim()) {
        exportFromJSON({
          data: this.rows.map(item => ({
            fullname: item.fullname,
            email: item.email,
            usertype: item.usertype,
            country: item.country,
            phone: item.phone,
            join_date: item.created_at,
            child_count: item.child_count,
          })),
          fileName,
          exportType,
        })
        return
      }
      const params = this.getSearchParam(1, this.paginationData.totalItems + 1)
      this.isProcessing = true
      useJwt
        .getUsers({
          params,
        }).then(response => {
          const responseData = response.data.data
          exportFromJSON({
            data: responseData.map(item => ({
              fullname: item.fullname,
              email: item.email,
              usertype: item.usertype,
              country: item.country,
              phone: item.phone,
              join_date: item.created_at,
              child_count: item.child_count,
            })),
            fileName,
            exportType,
          })
        }).finally(() => {
          this.isProcessing = false
        })
    },

    getSchoolUsers() {
      useApollo.getSchoolUsers().then(res => {
        this.schools = res.data.schools.data.map(school => ({ value: school.id, text: school.fullname }))
        if (this.schools.length) {
          this.getClassrooms()
        }
      })
    },
    getClassrooms() {
      if (!this.search.school) {
        return
      }
      useJwt.getClassRoomOfUser(this.search.school).then(response => {
        const auth = this.AUTH_USER()
        if (auth.usertype === 'teacher') {
          this.classRooms = [
            {
              label: 'Assigned',
              // eslint-disable-next-line eqeqeq
              options: response.data.data.filter(item => item.teacher_id == auth.id).map(res => ({ value: res.id, text: res.name, teacher_id: res.teacher_id })),
            },
            {
              label: 'As Co-Teacher',
              // eslint-disable-next-line eqeqeq
              options: response.data.data.filter(item => item.teacher_id != auth.id).map(res => ({ value: res.id, text: res.name, teacher_id: res.teacher_id })),
            },
          ]
        } else {
          this.classRooms = response.data.data.map(res => ({ value: res.id, text: res.name, teacher_id: res.teacher_id }))
        }
      })
    },
    getLibrary() {
      useJwt.getLibrary({ params: { nameList: 1 } }).then(response => {
        this.libraries = response.data.data.map(res => ({ value: res.id, text: res.name }))
      }).catch(error => {
        this.showError(error)
      })
    },
    deactivateUserConfirmation(user) {
      this.deactivateUserId = user.id
      this.deactivateModalTitle = this.$i18n.tc('deactivate-user.deactivate')
      this.showDeactivationModal = true
      this.deactivateType = (user.access_type === 'partial_deactivate' || user.access_type === 'full_deactivate') ? user.access_type : null
    },

    updateUserStatus(deactivateUserId, type) {
      this.isProcessingDeactivate = true
      useJwt.deactivateUser(deactivateUserId, { type })
        .then(response => {
          this.resetDeactivationState()
          this.showSuccessMessage(response)
          this.getUsers()
          this.getUsersCount()
        })
        .catch(error => {
          this.showErrorMessage(error)
          this.isProcessingDeactivate = false
        })
    },
    deactivateUser() {
      this.updateUserStatus(this.deactivateUserId, this.deactivateType)
    },
    activateUser() {
      this.updateUserStatus(this.deactivateUserId, 'free')
    },
    resetDeactivationState() {
      this.deactivateModalTitle = null
      this.deactivateType = null
      this.deactivateUserId = null
      this.showDeactivationModal = false
      this.isProcessingDeactivate = false
    },

    getUsersCount() {
      // console.log(this.search.accessType)
      const params = {
        parent_id: this.userCountParentId,
        access_type: this.search.accessType,
      }
      useJwt.getUsersCount({ params }).then(response => {
        this.usersCount = response.data.data
      })
    },
  },
}
</script>

<style>
.dark-layout  .vs__deselect {
  background: #fff !important;
  border-radius: 1px;
}
.password:placeholder-shown + .eye-icon .input-group-text .feather{
  display: none;
}
.generate-btn:focus {
  background-color: transparent !important;
}
.generate-btn:active {
  background-color: rgba(115, 103, 240, 0.2) !important;
}
</style>
